import React, { Link } from "react"
import "../styles/certifications.scss"
import Layout from "../components/layout"
import ScrollArrow from "../components/ScrollArrow"
import ScrollAnimation from "react-animate-on-scroll"
import SEO from "../components/seo"
import ButtonB from "../components/ButtonB"

const certificationsPage = () => (
  <Layout>
    <ScrollArrow showBelow={450} />

    <SEO title="Certifications" />
    <div className="certifications-hero-section">
      <div className="certifications-hero-content">
        <div className="transparent">
          <div className="outer-container">
            <div className="inner-container">
              <div className="column">
                <h1>Certifications</h1>
                <h2>Investing in people</h2>
                <ButtonB href="#our-accreditations">View More</ButtonB>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* OUR ACCREDITATIONS */}

    <div className="section" id="our-accreditations">
      <div className="cert-outer-container">
        <div
          className="inner-container"
          style={{
            paddingBottom: "0",
            paddingLeft: "2rem",
            paddingRight: "2rem",
          }}
        >
          <h2>Our Accreditations</h2>
          <ScrollAnimation animateIn="animate__fadeInLeft">
            <strong style={{ color: "rgb(16, 90, 133)", fontSize: "24px" }}>
              Metsi’s engineers have years of practical experience in delivering
              high tech complex software automation services.
            </strong>
          </ScrollAnimation>
        </div>

        <div className="outer-container">
          <div className="cert-inner-container1">
            <div className="certifipic"></div>
            <div
              className="certificontentmain"
              style={{ paddingTop: "20px", fontSize: "24px" }}
            >
              Aenean ornare dui eu felis condimentum ullamcorper. Curabitur sit
              amet dolor ipsum. Ut arcu risus, feugiat ac enim nec, pretium
              commodo diam. Praesent purus felis, euismod a mattis tincidunt,
              consectetur id ante. Curabitur scelerisque porttitor arcu nec
              suscipit. Donec congue risus non dolor cursus, id venenatis nunc
              lobortis. Maecenas varius interdum leo et tempus. Donec pulvinar
              lectus ut suscipit scelerisque. Quisque auctor rhoncus placerat.
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* COMPANY CERTIFIED LOGOS */}

    <div className="certifications-hero-section2">
      <div className="certifications-hero-content">
        <div className="outer-container">
          <div className="inner-container">
            <ScrollAnimation animateIn="animate__fadeIn">
              <h1 style={{ color: "rgb(16, 90, 133)" }}>
                Acquired Certifications
              </h1>
            </ScrollAnimation>
            <div className="column">
              {/* PUPPET */}

              <ScrollAnimation animateIn="animate__fadeIn">
                <div
                  className="cert-inner-container1"
                  style={{
                    borderBottom: "2px solid rgb(16, 90, 133)",
                    borderTop: "2px solid rgb(16, 90, 133)",
                  }}
                >
                  <div className="certifipic2"></div>
                  <div
                    className="certificontent"
                    style={{ paddingTop: "50px" }}
                  >
                    <li>Puppet Certified Professional</li>
                  </div>
                </div>
              </ScrollAnimation>

              {/* CISCO */}

              <ScrollAnimation animateIn="animate__fadeIn">
                <div
                  className="cert-inner-container2"
                  style={{ borderBottom: "2px solid rgb(16, 90, 133)" }}
                >
                  <div className="certificontent">
                    <li>CCNA Routing & Switching</li>
                    <li>CCNP Routing & Switching</li>
                    <li>CCIE SP & DC</li>
                    <li>Cisco SMB Specialisation for Engineers</li>
                    <li>Cisco Collaboration SaaS Authorisation</li>
                    <li>Cisco Workload Optimisation Manager ACE</li>
                  </div>
                  <div className="certifipic1"></div>
                </div>
              </ScrollAnimation>

              {/* APPDYNAMICS */}

              <ScrollAnimation animateIn="animate__fadeIn">
                <div
                  className="cert-inner-container1"
                  style={{ borderBottom: "2px solid rgb(16, 90, 133)" }}
                >
                  <div className="certifipic3"></div>
                  <div className="certificontent">
                    <li>AppDynamics Certified Associate Performance Analyst</li>
                    <li>AppDynamics SE Kickstarter Assessment</li>
                  </div>
                </div>
              </ScrollAnimation>

              {/* VMWARE */}

              <ScrollAnimation animateIn="animate__fadeIn">
                <div
                  className="cert-inner-container2"
                  style={{ borderBottom: "2px solid rgb(16, 90, 133)" }}
                >
                  <div className="certificontent">
                    <li>VMware Certified Associate - DCV</li>
                    <li>VMware Certified Professional on vSphere</li>
                    <li>VMware Technical Solutions Professional (VTSP)</li>
                    <li>VMware Solutions Professional (VSP)</li>
                  </div>

                  <div className="certifipic4"></div>
                </div>
              </ScrollAnimation>

              {/* TURBONOMIC */}

              <ScrollAnimation animateIn="animate__fadeIn">
                <div
                  className="cert-inner-container1"
                  style={{ borderBottom: "2px solid rgb(16, 90, 133)" }}
                >
                  <div className="certifipic5"></div>
                  <div
                    className="certificontent"
                    style={{ paddingTop: "10px" }}
                  >
                    <li>
                      Turbonomic / Cisco Workload Optimisation Manager ACE
                    </li>
                  </div>
                </div>
              </ScrollAnimation>
              {/* CITRIX */}

              <ScrollAnimation animateIn="animate__fadeIn">
                <div
                  className="cert-inner-container2"
                  style={{ borderBottom: "2px solid rgb(16, 90, 133)" }}
                >
                  <div className="certificontent">
                    <li>
                      CCP-V Citrix Certified Professional – Virtualisation
                    </li>
                    <li>CCA-V Citrix Certified Associate - Virtualisation</li>
                    <li>CCA Citrix Certified Administration XenApp</li>
                  </div>

                  <div className="certifipic6"></div>
                </div>
              </ScrollAnimation>

              {/* DYNATRACE */}

              <ScrollAnimation animateIn="animate__fadeIn">
                <div
                  className="cert-inner-container1"
                  style={{ borderBottom: "2px solid rgb(16, 90, 133)" }}
                >
                  <div className="certifipic7"></div>
                  <div
                    className="certificontent"
                    style={{ paddingTop: "50px" }}
                  >
                    <li>Dynatrace Associate Certification</li>
                  </div>
                </div>
              </ScrollAnimation>
              {/* NEOTYS */}

              <ScrollAnimation animateIn="animate__fadeIn">
                <div
                  className="cert-inner-container2"
                  style={{ borderBottom: "2px solid rgb(16, 90, 133)" }}
                >
                  <div className="certificontent">
                    <li>Neotys Certified Professional for NeoLoad</li>
                  </div>

                  <div className="certifipic8"></div>
                </div>
              </ScrollAnimation>

              {/* ITSM */}

              <ScrollAnimation animateIn="animate__fadeIn">
                <div
                  className="cert-inner-container1"
                  style={{ borderBottom: "2px solid rgb(16, 90, 133)" }}
                >
                  <div className="certifipic9"></div>
                  <div
                    className="certificontent"
                    style={{ paddingTop: "20px" }}
                  >
                    <li>Prince 2 Practitioner</li>
                    <li>ITIL V3 Foundation</li>
                  </div>
                </div>
              </ScrollAnimation>

              {/* AWS */}

              <ScrollAnimation animateIn="animate__fadeIn">
                <div
                  className="cert-inner-container2"
                  style={{ borderBottom: "2px solid rgb(16, 90, 133)" }}
                >
                  <div
                    className="certificontent"
                    style={{ paddingTop: "20px" }}
                  >
                    <li>AWS Certified Solutions Architect - Associate</li>
                  </div>
                  <div className="certifipic10"></div>
                </div>
              </ScrollAnimation>

              {/* IBM */}

              <ScrollAnimation animateIn="animate__fadeIn">
                <div
                  className="cert-inner-container1"
                  style={{
                    borderBottom: "2px solid rgb(16, 90, 133)",
                    maxHeight: "400vh",
                  }}
                >
                  <div className="certifipic11"></div>
                  <div
                    className="certificontent"
                    style={{ maxHeight: "400vh" }}
                  >
                    <li>
                      IBM Certified Administrator - Tivoli Monitoring V6.2.3
                    </li>
                    <li>
                      IBM Certified Deployment Professional - Tivoli Enterprise
                      Console V3.9
                    </li>
                    <li>
                      IBM Certified Deployment Professional - Tivoli On Demand
                      Automation Workflows V2.1
                    </li>
                    <li>
                      IBM Certified Deployment Professional - Tivoli
                      Configuration Manager V4.2.2
                    </li>
                    <li>
                      IBM Certified Deployment Professional - Tivoli
                      Provisioning Manager with Orchestration V3.1
                    </li>
                    <li>
                      IBM Certified Deployment Professional - Tivoli On Demand
                      Automation Workflows V3.1
                    </li>
                    <li>
                      IBM Certified Deployment Professional - Tivoli
                      Provisioning Manager V7.2.0.2
                    </li>
                    <li>
                      IBM Certified Deployment Professional - Tivoli Application
                      Dependency Discovery Manager V7.2.1.3
                    </li>
                    <li>
                      IBM Certified Deployment Professional - Tivoli Change and
                      Configuration Management Database V7.1.1
                    </li>
                    <li>
                      IBM Certified Deployment Professional - Tivoli Composite
                      Application Manager for Transactions V7.1
                    </li>
                    <li>
                      IBM Certified Deployment Professional - Tivoli Service
                      Automation Manager V7.2.1
                    </li>
                    <li>
                      IBM Certified Advanced Deployment Professional - Tivoli
                      Enterprise Management Solutions
                    </li>
                    <li>
                      IBM Certified Advanced Deployment Professional - Tivoli
                      Provisioning Management Solutions
                    </li>
                    <li>
                      IBM Certified Advanced Deployment Professional - Tivoli
                      Business Application Management Solutions
                    </li>
                    <li>
                      IBM Certified ADP - IBM Service Management Data Center
                      Management and Transformation V3
                    </li>
                    <li>
                      IBM Certified Solution Advisor - Tivoli Endpoint Manager
                      Solutions V1
                    </li>
                    <li>
                      IBM Certified Solution Advisor - Cloud Computing
                      Architecture V3
                    </li>
                    <li>
                      IBM Certified Solution Advisor - Tivoli Service Delivery
                      and Process Automation Solutions V3
                    </li>
                    <li>IBM System Administrator Certification</li>
                  </div>
                </div>
              </ScrollAnimation>

              {/* <ButtonB href="/about">Our Story</ButtonB> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default certificationsPage
